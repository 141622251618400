import React, { MouseEvent, PropsWithChildren } from 'react';

import {
  CircularProgress,
  Table as MaterialTable,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import classNames from 'classnames';

import { StyledBackdrop } from './Table.styled';
import { TableSearchBar } from './components';
import { LoadMoreRow } from './components/InfiniteScroll/LoadMoreRow';
import { TableLabel } from './components/TableLabel/TableLabel';
import { TableProps, TableRowItem, isColumnSortable } from './types';

export type Props<ItemType, ColumnIdType> = PropsWithChildren<TableProps<TableRowItem<ItemType>, ColumnIdType>>;

export const Table = <ItemType, ColumnIdType extends string = string>({
  columns,
  itemsList,
  emptyTableText = 'No data',
  onRowClick,
  getRowKey,
  isLoading = false,
  order,
  orderBy,
  onSortChange,
  fixedTableLayout,
  classes: customClasses,
  onHighlightedItemChange,
  hover = !!onRowClick,
  headerOverlay,
  hasMoreRows = false,
  onFetchNextPage,
  searchboxProps,
  paginationProps,
}: Props<ItemType, ColumnIdType>): JSX.Element => {
  const createSortHandler = (selectedOrderBy: ColumnIdType) => (event: MouseEvent<HTMLSpanElement>) => {
    onSortChange?.(event, selectedOrderBy);
  };

  return (
    <TableContainer sx={{ position: 'relative' }} component={Paper}>
      <TableSearchBar paginationProps={paginationProps} searchboxProps={searchboxProps} />
      <MaterialTable sx={{ tableLayout: fixedTableLayout ? 'fixed' : 'auto' }} size="small">
        <TableHead>
          {headerOverlay || (
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  className={classNames(column.classNames, customClasses?.headerCell)}
                  key={column.id}
                  align={column.align}
                  style={column.style}
                  width={column.width}
                  padding={column.padding}
                >
                  {column.headerFormat ? (
                    column.headerFormat()
                  ) : (
                    <TableLabel
                      column={column}
                      orderBy={orderBy}
                      order={order}
                      onSort={isColumnSortable(column) ? createSortHandler(column.id) : undefined}
                    />
                  )}
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableHead>
        <TableBody sx={{ height: isLoading && !itemsList.length ? 58 : undefined }}>
          {itemsList?.length ? (
            itemsList.map((row, index) => (
              <TableRow
                key={getRowKey ? getRowKey(row) : (row.id ?? index)}
                hover={row.disabled ? false : hover}
                tabIndex={-1}
                onClick={onRowClick && !row.disabled ? () => onRowClick(row) : undefined}
                onMouseEnter={() => onHighlightedItemChange?.(row)}
                onMouseLeave={() => onHighlightedItemChange?.(undefined)}
                data-testid={`row-${index}`}
              >
                {columns.map((column) => {
                  const value = column.format(row);
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={column.style}
                      width={column.width}
                      title={typeof value === 'string' ? value : ''}
                      padding={column.padding}
                      data-testid={`cell-${column.id}`}
                    >
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          ) : (
            <TableRow role="checkbox" tabIndex={-1}>
              <TableCell align="center" colSpan={columns.length} title={emptyTableText}>
                {!isLoading && emptyTableText}
              </TableCell>
            </TableRow>
          )}
          {hasMoreRows && !!onFetchNextPage && (
            // key property is required as we need to re-mount LoadMoreRow component when new data gets loaded
            <LoadMoreRow key={itemsList.length} colSpan={columns.length} onFetchNextPage={onFetchNextPage} />
          )}
        </TableBody>
      </MaterialTable>
      <StyledBackdrop open={isLoading} unmountOnExit mountOnEnter>
        <CircularProgress />
      </StyledBackdrop>
    </TableContainer>
  );
};
