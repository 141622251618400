import React, { FC, useMemo } from 'react';

import { Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { BarChartLabel, Tick, TooltipContentWrapper } from '../../components';
import {
  CHART_BAR_MAX_WIDTH,
  CHART_MIN_HEIGHT,
  legendWrapperStyle as defaultLegendWrapperStyle,
} from '../../constants';
import { useChartAxis, useChartStyles, useTooltip } from '../../hooks';
import { BasicChartProps } from '../../types';
import {
  calculateChartMargins,
  calculateTicks,
  createTickFormatter,
  getColor,
  mapToStackedChartValues,
} from '../../utils';

type Props = BasicChartProps & {
  limitBarWidth?: boolean;
};

export const VerticalStackedBarChart: FC<Props> = ({
  labelValues,
  labelNames,
  data,
  dataKeys = ['value'],
  height = CHART_MIN_HEIGHT,
  limitBarWidth = true,
  TooltipContent,
  showValueLabels = true,
  legendContent,
  legendWrapperStyle = defaultLegendWrapperStyle,
  isPercentageValue,
  legendFormatter,
  onReady,
}) => {
  const { getChartClass } = useChartStyles();
  const { tickStyles, renderAxisYLabel, renderAxisXLabel } = useChartAxis();
  const { activeKey, handleMouseEnter, hideTooltip } = useTooltip();
  const stackedValues = useMemo(() => mapToStackedChartValues({ data, dataKeys }), [data, dataKeys]);
  const { ticks, isLastTickEqualToMaxValue } = useMemo(() => calculateTicks({ data: stackedValues }), [stackedValues]);

  return (
    <ResponsiveContainer debounce={300} width="100%" height={height}>
      <BarChart
        data={data}
        margin={calculateChartMargins({ labelNames, labelValues })}
        className={getChartClass('vertical', isLastTickEqualToMaxValue)}
        barCategoryGap={1}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="name"
          tickLine={false}
          axisLine={false}
          tick={(props) => <Tick {...props} axis="X" />}
          tickMargin={16}
          interval={0}
          label={renderAxisXLabel(labelNames)}
        />
        <YAxis
          tickLine={false}
          axisLine={false}
          interval={0}
          type="number"
          domain={[0, 'dataMax']}
          ticks={ticks}
          allowDataOverflow
          allowDecimals={false}
          tickCount={10}
          tick={tickStyles}
          label={renderAxisYLabel(labelValues)}
          tickFormatter={createTickFormatter(isPercentageValue)}
        />
        <Tooltip
          cursor={false}
          content={<TooltipContentWrapper activeDataKey={activeKey} TooltipContent={TooltipContent} />}
          allowEscapeViewBox={{ x: true, y: true }}
        />
        {dataKeys.length > 1 && (
          <Legend
            verticalAlign="top"
            align="right"
            formatter={legendFormatter}
            wrapperStyle={legendWrapperStyle}
            content={legendContent}
          />
        )}

        {dataKeys.map((key, index) => {
          const color = getColor(index);

          return (
            <Bar
              key={key}
              stackId="stack1"
              dataKey={key}
              maxBarSize={limitBarWidth ? CHART_BAR_MAX_WIDTH : undefined}
              fill={color}
              onMouseEnter={handleMouseEnter(key)}
              onMouseLeave={hideTooltip}
              onAnimationEnd={onReady}
            >
              {showValueLabels && <LabelList dataKey={key} fill={color} content={BarChartLabel} />}
            </Bar>
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};
