import React, { FC, useMemo } from 'react';

import { SxProps, Theme } from '@mui/material';
import { Tooltip } from 'common/components';
import { Community } from 'domain/Community';
import { flow, head, join, map, take, toUpper, words } from 'lodash/fp';

import { Logo, LogoProps } from '../Logo';
import { CommunityLogoAvatar } from './CommunityLogo.styled';

const getCommunityInitials = (name: string, count: number) =>
  flow(words, map(flow(head, toUpper)), take(count), join(''))(name);

type CommunityLogoProps = Pick<LogoProps, 'variant' | 'size'> & {
  community?: Community | null;
  centered?: boolean;
  sx?: SxProps<Theme>;
};

export const CommunityLogo: FC<CommunityLogoProps> = ({ community, centered, variant, size, sx }) => {
  const logoLink = community?.logoImageLinkSmall ?? community?.logoImageLink;
  const communityInitials = useMemo(
    () => (community?.name ? getCommunityInitials(community.name, size === 'small' ? 1 : 3) : undefined),
    [size, community?.name]
  );

  return !logoLink && community?.name ? (
    <Tooltip title={community.name}>
      <CommunityLogoAvatar
        brandingColor={community?.brandingColor}
        variant="rounded"
        size={size}
        sx={sx}
        data-testid="Logo"
      >
        {communityInitials}
      </CommunityLogoAvatar>
    </Tooltip>
  ) : (
    <Logo logoImageLink={logoLink} centered={centered} variant={variant} size={size} sx={sx} />
  );
};
