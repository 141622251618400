import { styled } from '@mui/material';
import { omitForwardedProps } from 'common/utils';

import { Button } from '../../Button';

type Props = {
  isActive?: boolean;
};
export const StyleButton = styled(Button, { shouldForwardProp: omitForwardedProps<Props>('isActive') })<Props>(
  ({ theme: { palette }, color, variant }) => ({
    height: 48,
    width: 48,
    minWidth: 48,
    padding: 0,
    borderRadius: '50%',

    ...(variant === 'text' && {
      border: '1px solid #3D3D3D',
      color: 'inherit',
    }),

    '&.Mui-focusVisible': {
      borderColor: 'transparent',
    },

    ...(variant === 'contained' &&
      !color && {
        color: palette.common.black,
        backgroundColor: palette.common.white,

        '&:hover': {
          backgroundColor: palette.grey[200],
        },
      }),
  })
);
