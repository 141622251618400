export const CONNECTION_HISTORY_LENGTH = 10;

/**
 * According to https://api-ref.agora.io/en/video-sdk/web/4.x/interfaces/networkquality.html
 * 4: Users can communicate with each other, but not very smoothly.
 */
export const NETWORK_QUALITY_BOTTOM_THRESHOLD = 4 * CONNECTION_HISTORY_LENGTH;

/**
 * According to https://api-ref.agora.io/en/video-sdk/web/4.x/interfaces/networkquality.html
 * 5: The quality is so poor that users can barely communicate.
 */
export const NETWORK_QUALITY_TOP_THRESHOLD = 5 * CONNECTION_HISTORY_LENGTH;
