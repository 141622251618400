import { useCallback, useMemo } from 'react';

export const useLocalStorage = <T = {}>({ key }: { key: string }) => {
  const data = useMemo(() => {
    const storedDataString = window.localStorage.getItem(key);

    if (!storedDataString) return;

    try {
      return JSON.parse(storedDataString) as T;
    } catch {
      return undefined;
    }
  }, [key]);

  const storeData = useCallback(
    (dataToSave: unknown) => {
      window.localStorage.setItem(key, JSON.stringify(dataToSave));
    },
    [key]
  );

  const removeStoredData = useCallback(() => {
    window.localStorage.removeItem(key);
  }, [key]);

  return {
    data,
    storeData,
    removeStoredData,
  };
};
