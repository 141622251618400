import type { ClientRole, NetworkQuality } from 'agora-rtc-react';
import { MediaTrackErrorKey } from 'devices';
import { MediaTrackState } from 'domain/Broadcast';

type MediaTrack = {
  currentDeviceId: string | null;
  error: MediaTrackErrorKey | null;
  isPublished: boolean;
  state: MediaTrackState;
};

export type AgoraState = {
  isJoined: boolean;
  audioTrack: MediaTrack;
  videoTrack: MediaTrack;
  networkQuality: NetworkQuality[];
  role?: ClientRole;
};

export enum NetworkQualityState {
  Unknown,
  Excellent,
  Good,
  SlightlyImpared,
  Poor,
  BarelyUsable,
  Disconnected,
}

export enum NetworkErrorKey {
  UnstableConnection = 'unstableConnection',
  UnstableUplinkConnection = 'unstableUplinkConnection',
}
