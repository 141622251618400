import React, { FC, useMemo } from 'react';

import { Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { BarChartLabel, TooltipContentWrapper } from '../../components';
import { CHART_MIN_HEIGHT, legendWrapperStyle as defaultLegendWrapperStyle } from '../../constants';
import { useChartAxis, useChartStyles, useTooltip } from '../../hooks';
import { BasicChartProps } from '../../types';
import { calculateChartMargins, calculateTicks, getColor } from '../../utils';

export type HistogramProps = BasicChartProps;

export const Histogram: FC<HistogramProps> = ({
  labelValues,
  labelNames,
  data,
  dataKeys = ['value'],
  height = CHART_MIN_HEIGHT,
  TooltipContent,
  legendContent,
  legendWrapperStyle = defaultLegendWrapperStyle,
  legendFormatter,
  onReady,
}) => {
  const { getChartClass } = useChartStyles();
  const { tickStyles, axisLineStyles, renderAxisYLabel, renderAxisXLabel } = useChartAxis();
  const { ticks: yTicks, isLastTickEqualToMaxValue } = useMemo(
    () => calculateTicks({ data, dataKeys }),
    [data, dataKeys]
  );
  const { activeKey, handleMouseEnter } = useTooltip();
  const xTicks = useMemo(() => Array.from({ length: data.length + 1 }).map((_, index) => -0.5 + index), [data]);
  const xDomain = [-0.5, data.length - 0.5];
  const showLegend = dataKeys.length || legendContent;
  const xTickFormatter = (_value: unknown, index: number) => {
    if (index >= data.length) {
      const { x0, x1 } = data[data.length - 1];
      if (x0 === x1) return `${Number(x1) + 1}`;
      return `${x1}`;
    }
    return `${data[index].x0}`;
  };

  return (
    <ResponsiveContainer debounce={300} width="100%" height={height}>
      <BarChart
        barCategoryGap={1}
        barGap={2}
        data={data}
        margin={calculateChartMargins({ labelNames, labelValues })}
        layout="horizontal"
        className={getChartClass('vertical', isLastTickEqualToMaxValue)}
      >
        <CartesianGrid vertical={false} />
        <XAxis hide dataKey="name" type="number" domain={xDomain} allowDataOverflow={true} />
        <XAxis
          xAxisId="ticks"
          type="number"
          domain={xDomain}
          allowDataOverflow={true}
          tickLine={false}
          axisLine={false}
          ticks={xTicks}
          tick={tickStyles}
          tickFormatter={xTickFormatter}
          label={renderAxisXLabel(labelNames)}
        />
        <YAxis
          tickLine={false}
          axisLine={axisLineStyles}
          interval={0}
          ticks={yTicks}
          tick={tickStyles}
          label={renderAxisYLabel(labelValues)}
          width={40}
          domain={[0, 'dataMax']}
          allowDataOverflow
        />
        <Tooltip
          cursor={false}
          content={<TooltipContentWrapper activeDataKey={activeKey} TooltipContent={TooltipContent} />}
          allowEscapeViewBox={{ x: true, y: true }}
        />
        {showLegend && (
          <Legend
            verticalAlign="top"
            align="right"
            formatter={legendFormatter}
            wrapperStyle={legendWrapperStyle}
            content={legendContent}
          />
        )}
        {dataKeys.map((key, dataKeyIndex) => (
          <Bar
            key={key}
            dataKey={key}
            onMouseEnter={handleMouseEnter(key)}
            fill={getColor(dataKeyIndex)}
            onAnimationEnd={onReady}
          >
            <LabelList dataKey={key} content={BarChartLabel} fill={getColor(dataKeyIndex)} />
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};
