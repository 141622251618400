import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { VideocamIcon, VideocamOffIcon } from 'icons';

import { ActionButton, ActionButtonProps } from './ActionButton';

type ToggleCameraProps = Pick<ActionButtonProps, 'onClick' | 'isActive' | 'isDisabled' | 'isLoading'> & {
  tooltip?: string;
};

export const ToggleCameraButton: FC<ToggleCameraProps> = ({ isActive, tooltip, ...props }) => {
  const { t } = useTranslation('devices');

  return (
    <ActionButton
      tooltip={tooltip || (isActive ? t('turnOffCamera') : t('turnOnCamera'))}
      contained={!isActive}
      color={isActive ? undefined : 'error'}
      {...props}
    >
      {isActive ? <VideocamIcon fontSize="medium" /> : <VideocamOffIcon fontSize="medium" />}
    </ActionButton>
  );
};
