import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ajaxService } from 'modules/api/services';
import { UrlNames } from 'modules/app/constants';
import { configService } from 'modules/app/services';

import { socketSlice } from './socketSlice';
import { SocketSendParams, SocketSubscribeParams } from './types';

export const {
  addConnectedRoom,
  removeConnectedRoom,
  websocketConnected,
  websocketDisconnected,
  websocketReconnecting,
  websocketCleanupAfterReconnect,
  websocketResetConnectionStats,
} = socketSlice.actions;

export const websocketSubscribe = createAction<SocketSubscribeParams, 'websocketSubscribe'>('websocketSubscribe');
export const websocketUnsubscribe = createAction<SocketSubscribeParams, 'websocketUnsubscribe'>('websocketUnsubscribe');
export const websocketSubscribeSuccess = createAction<{ roomName: string }, 'websocketSubscribeSuccess'>(
  'websocketSubscribeSuccess'
);
export const websocketSend = createAction<SocketSendParams, 'websocketSend'>('websocketSend');

export const websocketForceDisconnect = createAsyncThunk<void>('websocketForceDisconnect', async () => {
  await ajaxService.axios.post(`${configService.getUrl(UrlNames.Api)}/session/close`);
});
export const websocketOpenPublicConnection = createAction('websocketOpenPublicConnection');
