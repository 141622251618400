import React, { ComponentProps, FC } from 'react';

import { Tooltip } from 'common/components/Tooltip';

import { StyleButton } from './ActionButton.styled';

export type ActionButtonProps = ComponentProps<typeof StyleButton> & {
  tooltip: string;
  isDisabled?: boolean;
  contained?: boolean;
  'data-testid'?: string;
};

export const ActionButton: FC<ActionButtonProps> = ({ tooltip, isDisabled, contained, children, ...props }) => (
  <Tooltip title={tooltip} placement="top">
    <StyleButton
      variant={contained ? 'contained' : 'text'}
      size="large"
      disabled={isDisabled}
      aria-label={tooltip}
      {...props}
    >
      {children}
    </StyleButton>
  </Tooltip>
);
