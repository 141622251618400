import { createAsyncThunk } from '@reduxjs/toolkit';
import { find } from 'lodash';
import { replace } from 'redux-first-history';
import { RootState } from 'store';
import { selectCommunityBySlug, selectMyCommunities } from 'store/apis/community';
import { selectUserAccountId } from 'store/apis/user';

import { selectLocationSearch } from '../router';
import { setActiveCommunityId } from './communitySlice';
import { selectActiveCommunityId } from './selectors';

export const setPreferredCommunity = createAsyncThunk(
  'community/setPreferredCommunity',
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const accountId = selectUserAccountId(state);
    const myCommunities = selectMyCommunities(state)?.data?.myCommunities;
    const searchParams = selectLocationSearch(state);
    const communitySlugFromRoute = searchParams.get('community');
    const communityFromRoute = communitySlugFromRoute ? selectCommunityBySlug(communitySlugFromRoute)(state) : null;
    const activeCommunityId = selectActiveCommunityId(state);
    const storedCommunityId = localStorage.getItem(`preferredCommunityId-user${accountId}`);

    const newCommunityId = communityFromRoute?.id || activeCommunityId || storedCommunityId;
    const doesCommunityExist = !!find(myCommunities, { id: Number(newCommunityId) });
    const communityId = doesCommunityExist ? newCommunityId : myCommunities?.[0]?.id;

    if (communityId && activeCommunityId !== communityId) {
      dispatch(setActiveCommunityId(Number(communityId)));
    }

    if (communitySlugFromRoute) {
      searchParams.delete('community');
      dispatch(replace({ search: searchParams.toString() }));
    }
  }
);
