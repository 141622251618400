import { browserName, isChrome, isChromium, isEdgeChromium, isFirefox } from 'react-device-detect';

import { convertNumberToAbbreviation } from './number';

const isBrowserWarning = isFirefox;
export const isBrowserSupported = [
  isChrome,
  isEdgeChromium,
  isChromium,
  ['Chrome Headless', 'Brave'].includes(browserName),
].some(Boolean);
export const isBrowserError = !(isBrowserSupported || isBrowserWarning);

export const toPercentageString = (value: number, total: number) =>
  `${convertNumberToAbbreviation((value * 100) / total, true)}%`;
