import { Drawer, styled } from '@mui/material';

export const DRAWER_WIDTH = 240;
export const COLLAPSED_DRAWER_WIDTH = 74;

export const DesktopDrawer = styled(Drawer)(({ theme: { zIndex }, open }) => ({
  width: 'inherit',
  flexShrink: 0,
  '& .MuiDrawer-paper': { width: 'inherit' },
  zIndex: open ? zIndex.appBar - 1 : undefined,
}));
