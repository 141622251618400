import React, { FC, useMemo, useState } from 'react';

import { useElementSize } from 'common/hooks';
import { Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { BarChartLabel, Tick, TooltipContentWrapper } from '../../components';
import { legendWrapperStyle } from '../../constants';
import { useChartAxis, useChartStyles, useDynamicChartDimensions, useTooltip } from '../../hooks';
import { BasicChartProps } from '../../types';
import { calculateChartMargins, calculateTicks, createTickFormatter, getColor } from '../../utils';

export const HorizontalBarChart: FC<BasicChartProps> = ({
  labelValues,
  labelNames,
  data,
  height,
  dataKeys = ['value'],
  TooltipContent,
  isPercentageValue,
  onClick,
  legendFormatter,
  onReady,
}) => {
  const { getChartClass } = useChartStyles();
  const { tickStyles, tickLineStyles, axisLineStyles, renderAxisYLabel, renderAxisXLabel } = useChartAxis();
  const { ticks, isLastTickEqualToMaxValue } = useMemo(() => calculateTicks({ data, dataKeys }), [data, dataKeys]);
  const { activeKey, handleMouseEnter } = useTooltip();
  const [activeIndex, setActiveIndex] = useState<number | undefined>();
  const [ref, size] = useElementSize();
  const { yAxisWidth, chartHeight } = useDynamicChartDimensions({
    data,
    chartWidth: size.width,
    chartHeight: height,
  });

  return (
    <ResponsiveContainer debounce={300} width="100%" height={chartHeight} ref={ref}>
      <BarChart
        data={data}
        margin={calculateChartMargins({ labelNames, labelValues })}
        layout="vertical"
        className={getChartClass('horizontal', isLastTickEqualToMaxValue)}
      >
        <CartesianGrid strokeDasharray="1 1" horizontal={false} />
        <YAxis
          dataKey="name"
          type="category"
          tickLine={false}
          axisLine={false}
          tick={(props) => <Tick {...props} onClick={onClick} activeIndex={activeIndex} />}
          interval={0}
          width={yAxisWidth}
          label={renderAxisYLabel(labelNames)}
        />
        <XAxis
          tick={tickStyles}
          tickLine={tickLineStyles}
          tickSize={10}
          axisLine={axisLineStyles}
          tickMargin={16}
          interval={0}
          type="number"
          domain={[0, 'dataMax']}
          ticks={ticks}
          allowDataOverflow
          label={renderAxisXLabel(labelValues)}
          tickFormatter={createTickFormatter(isPercentageValue)}
        />

        <Tooltip
          cursor={false}
          content={<TooltipContentWrapper activeDataKey={activeKey} TooltipContent={TooltipContent} />}
          allowEscapeViewBox={{ x: true, y: true }}
        />
        {dataKeys.length > 1 && (
          <Legend verticalAlign="top" align="right" formatter={legendFormatter} wrapperStyle={legendWrapperStyle} />
        )}
        {dataKeys.map((key, index) => {
          const color = getColor(index);
          return (
            <Bar
              key={key}
              dataKey={key}
              maxBarSize={30}
              fill={color}
              onMouseEnter={(_e, i) => {
                handleMouseEnter(key)();
                setActiveIndex(i);
              }}
              onMouseLeave={() => setActiveIndex(undefined)}
              onClick={(_, i) => onClick?.(i)}
              cursor={onClick ? 'pointer' : 'default'}
              onAnimationEnd={onReady}
            >
              <LabelList dataKey={key} fill={color} content={<BarChartLabel isPercentageValue={isPercentageValue} />} />
            </Bar>
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};
