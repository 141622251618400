import { Divider, Stack, Typography, styled } from '@mui/material';

export const BoxWithLabelWrapper = styled(Stack)(({ theme: { palette, spacing } }) => ({
  border: '1px solid',
  borderColor: palette.grey[300],
  borderRadius: '4px',
  padding: spacing(0, 2),
  gap: spacing(3),
  alignItems: 'center',
  flexDirection: 'row',
  backgroundColor: palette.mode === 'light' ? palette.common.white : palette.common.black,

  '& .MuiInputBase-root': {
    minHeight: 46,
  },
}));

export const BoxWithLabelText = styled(Typography)<{ disabled: boolean }>(
  ({ theme: { palette, spacing }, disabled }) => ({
    color: disabled ? palette.action.disabled : palette.grey[600],
    padding: spacing(0, 1, 0, 0),
  })
);

export const BoxWithLabelDivider = styled(Divider)(({ theme: { palette } }) => ({
  borderColor: palette.grey[300],
}));
