import React, { FC } from 'react';

import { natterTypographyVariants } from 'common/theme/typography';
import { LegendProps } from 'recharts';

import { LegendColorSquare, LegendItem, LegendItemText, LegendList, LegendRoot } from './VerticalLegend.styled';

type Props = LegendProps & { isPngPreview?: boolean };

export const VerticalLegend: FC<Props> = ({ payload, isPngPreview }) => {
  if (!payload) return null;

  return (
    <LegendRoot>
      <LegendList>
        {payload.map(({ color, value }) => (
          <LegendItem key={value}>
            <LegendColorSquare bgcolor={color} />
            <LegendItemText
              style={natterTypographyVariants['natter-text-sm']}
              title={value}
              sx={
                isPngPreview
                  ? {
                      WebkitLineClamp: 'none',
                    }
                  : undefined
              }
            >
              {value}
            </LegendItemText>
          </LegendItem>
        ))}
      </LegendList>
    </LegendRoot>
  );
};
