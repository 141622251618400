import { createApi } from '@reduxjs/toolkit/query/react';
import { getApiBaseUrl } from 'modules/api/utils';

import axiosBaseQuery from '../_axiosBaseQuery';
import { TechCheckResultRequest } from './types';

export const techCheckApi = createApi({
  reducerPath: 'techCheckApi',
  baseQuery: axiosBaseQuery({ baseURL: getApiBaseUrl('v1') }),
  endpoints: (builder) => ({
    postTechCheckResult: builder.mutation<void, TechCheckResultRequest>({
      query: (data) => ({
        url: `/techcheck`,
        method: 'POST',
        data,
      }),
    }),
  }),
});

export const { usePostTechCheckResultMutation } = techCheckApi;
