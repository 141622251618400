import { BroadcastDemoteSpeakerApiPayload, BroadcastSpeakerApiPayload } from 'domain/Broadcast';
import { Tag, TagsCategory } from 'domain/Common';
import { CommunityMember, CommunityMemberFormData } from 'domain/Community';
import { UserPrivilegeDetails } from 'domain/UserProfile';
import { EventRoomAttendee, EventRoomAttendeeApiPayload, MeetingGuest, MeetingGuestApiPayload } from 'domain/event';

export const mapMemberToApi = (
  currentMemberData: CommunityMember | undefined,
  data: Partial<CommunityMemberFormData>
): Partial<CommunityMember> => ({
  role: data.role ?? currentMemberData?.role,
  tagsCategories: data.tagsCategories?.length ? data.tagsCategories : currentMemberData?.tagsCategories,
  privileges: data.privileges?.map((p) => ({
    name: p,
    id: UserPrivilegeDetails[p].id,
  })),
});

export const filterSelectedTags = (tagsCategories: TagsCategory[]): TagsCategory[] =>
  tagsCategories.map((c) => ({
    ...c,
    tags: c.tags
      .filter((t) => t.isSelected)
      .map<Tag>((t) => ({
        id: t.id,
        name: t.name,
      })),
  }));

type PayloadType = MeetingGuestApiPayload | BroadcastSpeakerApiPayload | BroadcastDemoteSpeakerApiPayload;

const getName = (data: PayloadType) => {
  if ('name' in data) {
    return data.name;
  }
  if ('userName' in data) {
    return data.userName;
  }
  return '';
};

export const mapToMeetingGuestFromApi = (data: PayloadType): MeetingGuest => ({
  id: Number('id' in data ? data.id : data.accountId),
  name: getName(data),
  avatarLink: 'avatarLink' in data && data.avatarLink !== 'null' ? data.avatarLink : '',
  role: data.role ?? '',
  category: 'category' in data ? data.category : undefined,
  room: 'room' in data ? data.room : undefined,
});

export const mapToEventRoomAttendeeFromApi = (data: Partial<EventRoomAttendeeApiPayload>): EventRoomAttendee => ({
  name: data.userName ?? '',
  category: data.userCategory,
  id: Number(data.userId),
  tagsCategories: data.tagsCategories ?? [],
  avatarLink: data.avatarLink ?? '',
  role: data.role ?? '',
  room: data.roomId ?? '',
});
