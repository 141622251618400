import { logger } from 'common/services';

export const handleMediaAccessError = (error: unknown) => {
  if (error instanceof Error) {
    /**
     * @see https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#exceptions
     * NotReadableError means that there might be an issue with a device which we'll handle separately,
     * specifically when the device wants to be accessed
     * Otherwise return false because other errors are related to document state, user denied access, etc.
     */

    logger.addBreadcrumb('[checkMediaAccess] error while checking media access', { data: error });
    return error.name === 'NotReadableError';
  }

  logger.error(error);
  return false;
};

export const checkMediaAccess = async (selectedDevice?: {
  cameraId: string;
  microphoneId: string;
}): Promise<boolean> => {
  try {
    logger.addBreadcrumb('[checkMediaAccess] Checking media access', {
      data: { selectedDevice },
    });
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: selectedDevice ? { deviceId: selectedDevice.microphoneId } : true,
      video: selectedDevice ? { deviceId: selectedDevice.cameraId } : true,
    });
    disposeMediaStream(stream);
    return true;
  } catch (error: unknown) {
    return handleMediaAccessError(error);
  }
};

export const disposeMediaStream = (stream: MediaStream) => {
  const tracks: MediaStreamTrack[] = stream.getTracks();
  for (const track of tracks) {
    track.stop();
    stream.removeTrack(track);
  }
};

export const checkAudioAccess = async (): Promise<boolean> => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });
    disposeMediaStream(stream);
    return true;
  } catch (error: unknown) {
    return handleMediaAccessError(error);
  }
};

export const checkVideoAccess = async (): Promise<boolean> => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: true,
    });
    disposeMediaStream(stream);
    return true;
  } catch (error: unknown) {
    return handleMediaAccessError(error);
  }
};
