import React, { FC } from 'react';

import { Toolbar } from '@mui/material';
import { useBreakpoints } from 'common/hooks';
import { useAppSelector } from 'store';
import { selectIsDrawerDisplayed, selectMobileMenuOpen } from 'store/features/app';

import { MobileDrawer } from './Sidebar.styled';
import { DesktopSidebar } from './components';

type Props = {
  enableCollapse?: boolean;
};

export const Sidebar: FC<Props> = ({ enableCollapse = false, children }) => {
  const displayDrawer = useAppSelector(selectIsDrawerDisplayed);
  const mobileMenuOpen = useAppSelector(selectMobileMenuOpen);
  const { isMobile } = useBreakpoints();

  return isMobile ? (
    <MobileDrawer
      open={mobileMenuOpen && displayDrawer}
      variant="temporary"
      anchor="top"
      ModalProps={{ keepMounted: true }}
    >
      <Toolbar />
      {children}
    </MobileDrawer>
  ) : (
    <DesktopSidebar enableCollapse={enableCollapse}>{children}</DesktopSidebar>
  );
};
