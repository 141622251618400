import React, { FC } from 'react';

import { SxProps, Theme } from '@mui/material';
import { LogoContainer as NatterLogo } from 'common/components/Logo';

import { LogoContainer } from './Logo.styled';

export type LogoProps = {
  height?: number;
  maxWidth?: string;
  centered?: boolean;
  logoImageLink?: string;
  variant?: 'default' | 'square';
  size?: 'default' | 'small';
  sx?: SxProps<Theme>;
};

export const Logo: FC<LogoProps> = ({
  height = 40,
  maxWidth = '100%',
  centered = true,
  logoImageLink,
  variant = 'default',
  size = 'default',
  sx,
}) => (
  <LogoContainer height={height} maxWidth={maxWidth} centered={centered} variant={variant} size={size} sx={sx}>
    {logoImageLink ? <img src={logoImageLink} alt={logoImageLink} data-testid="Logo" /> : <NatterLogo />}
  </LogoContainer>
);
