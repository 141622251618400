import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { MicrophoneOffIcon, MicrophoneOnIcon } from 'icons';

import { ActionButton, ActionButtonProps } from './ActionButton';

type ToggleMicrophoneProps = Pick<ActionButtonProps, 'onClick' | 'isActive' | 'isDisabled' | 'isLoading'> & {
  tooltip?: string;
};

export const ToggleMicrophone: FC<ToggleMicrophoneProps> = ({ isActive, tooltip, ...props }) => {
  const { t } = useTranslation('devices');

  return (
    <ActionButton
      tooltip={tooltip || (isActive ? t('turnOffMicrophone') : t('turnOnMicrophone'))}
      contained={!isActive}
      color={isActive ? undefined : 'error'}
      {...props}
    >
      {isActive ? <MicrophoneOnIcon fontSize="medium" /> : <MicrophoneOffIcon fontSize="medium" />}
    </ActionButton>
  );
};
