import React, { FC } from 'react';

import { Alert, type AlertProps } from '@mui/material';

type BannerProps = Pick<AlertProps, 'children' | 'severity' | 'sx'> & {
  showIcon?: boolean;
  sx?: AlertProps['sx'];
};

export const Banner: FC<BannerProps> = ({ showIcon = true, ...alertProps }) => (
  <Alert icon={showIcon === false ? showIcon : undefined} {...alertProps} />
);
