import React, { FC } from 'react';

import { Cell, Legend, Pie, PieChart as RechartsPieChart, ResponsiveContainer, Tooltip } from 'recharts';

import { PieChartLabel, TooltipContentWrapper, VerticalLegend } from '../../components';
import { CHART_MIN_HEIGHT, pieChartLegendWrapperStyle } from '../../constants';
import { useChartStyles, usePieAutoSizing, usePieData } from '../../hooks';
import { BasicChartProps } from '../../types';
import { getColor } from '../../utils';

const ANIMATION_DURATION = 800;

export const PieChart: FC<BasicChartProps> = ({
  data,
  height: initialHeight = CHART_MIN_HEIGHT,
  TooltipContent,
  dataKeys = ['value'],
  legendFormatter,
  onReady,
}) => {
  const classes = useChartStyles();
  const { wrapperRef, legendRef, outerRadius, height } = usePieAutoSizing({
    initialHeight,
  });
  const { chartData, legendData } = usePieData({
    data,
    dataKeys,
  });

  return (
    <ResponsiveContainer debounce={300} width="100%" height={height} ref={wrapperRef}>
      <RechartsPieChart className={classes.chart}>
        <Pie
          activeIndex={0}
          data={chartData}
          cx="50%"
          cy="50%"
          outerRadius={outerRadius}
          dataKey={dataKeys[0]}
          stroke="none"
          animationDuration={ANIMATION_DURATION}
          label={(props) => <PieChartLabel {...props} radiusDistance={0.6} />}
          labelLine={false}
          onAnimationEnd={onReady}
        >
          {chartData.map((_entry, index) => (
            <Cell key={`cell-${index}`} fill={getColor(index)} />
          ))}
        </Pie>
        <Tooltip
          cursor={false}
          content={<TooltipContentWrapper TooltipContent={TooltipContent} />}
          allowEscapeViewBox={{ x: true, y: true }}
        />
        <Legend
          verticalAlign="top"
          align="right"
          layout="vertical"
          wrapperStyle={pieChartLegendWrapperStyle}
          content={<VerticalLegend />}
          formatter={legendFormatter}
          payload={legendData}
          ref={legendRef}
        />
      </RechartsPieChart>
    </ResponsiveContainer>
  );
};
