import { logger } from 'common/services';
import { Effect } from 'store';

import { addAppErrorMessage } from '../alerts';
import {
  selectActiveAudioInputDevice,
  selectActiveVideoDevice,
  setAudioInputDeviceId,
  setVideoDeviceId,
} from '../user';
import { agoraActions } from './agora.actions';
import { selectCurrentCameraDeviceId, selectCurrentMicrophoneDeviceId } from './agora.selectors';

// Audio related effects

export const audioTrackCreationCompletedEffect: Effect = (_, { dispatch, getState }) => {
  const activeAudioInputDevice = selectActiveAudioInputDevice(getState());

  if (activeAudioInputDevice?.deviceId) {
    dispatch(agoraActions.audioTrack.deviceChange.completed(activeAudioInputDevice?.deviceId));
  }
};

export const audioTrackTogglingStartedEffect: Effect = ({ payload }) => {
  logger.addBreadcrumb('[useAudio] toggling microphone track', { data: { shouldEnableAudio: payload } });
};

export const audioTrackTogglingFailedEffect: Effect = (_, { dispatch }) => {
  dispatch(addAppErrorMessage('Sorry we were unable to toggle your microphone at this time. Please try again.'));
};

export const microphoneDeviceChangeFailedEffect: Effect = (_, { dispatch, getState }) => {
  const currentMicrophoneId = selectCurrentMicrophoneDeviceId(getState());

  if (currentMicrophoneId) {
    dispatch(setAudioInputDeviceId(currentMicrophoneId));
  }
  dispatch(addAppErrorMessage('Sorry we were unable to change your microphone at this time. Please try again.'));
};

// Video related effects

export const videoTrackCreationCompletedEffect: Effect = (_, { dispatch, getState }) => {
  const activeVideoDevice = selectActiveVideoDevice(getState());

  if (activeVideoDevice?.deviceId) {
    dispatch(agoraActions.audioTrack.deviceChange.completed(activeVideoDevice?.deviceId));
  }
};

export const videoTrackTogglingStartedEffect: Effect = ({ payload }) => {
  logger.addBreadcrumb('[useVideo] toggling camera track', { data: { shouldEnableVideo: payload } });
};

export const videoTrackTogglingFailedEffect: Effect = (_, { dispatch }) => {
  dispatch(addAppErrorMessage('Sorry we were unable to toggle your camera at this time. Please try again.'));
};

export const cameraDeviceChangeFailedEffect: Effect = (_, { dispatch, getState }) => {
  const currentCameraId = selectCurrentCameraDeviceId(getState());

  if (currentCameraId) {
    dispatch(setVideoDeviceId(currentCameraId));
  }
  dispatch(addAppErrorMessage('Sorry we were unable to change your camera at this time. Please try again.'));
};
