import { MediaTrackErrorKey } from '../types';
import { isAgoraRTCError } from './isAgoraRTCError';

export const getDeviceErrorMessage = (error: unknown): MediaTrackErrorKey => {
  if (
    error &&
    typeof error === 'object' &&
    'message' in error &&
    (error as { message: string }).message.includes('in use')
  ) {
    return 'deviceAlreadyInUse';
  }

  if (isAgoraRTCError(error)) {
    return error.message.includes('in use') ? 'deviceAlreadyInUse' : 'notReadableError';
  }

  return 'unexpectedError';
};
