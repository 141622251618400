import { Step } from 'common/components';

const createStep = (step: Partial<Step> & { title: string }): Step => ({
  status: 'NOT_STARTED',
  ...step,
});

export const INITIAL_STEPS: Step[] = [
  createStep({
    title: 'Event Details',
    description: 'Add scheduling and media',
    status: 'IN_PROGRESS',
  }),
  createStep({
    title: 'Entry Survey',
    description: 'Pre-Event Questions',
  }),
  createStep({
    title: 'Natter',
    description: 'Add Conversation Topics',
  }),
  createStep({
    title: 'Exit Survey',
    description: 'Post-Event Questions',
  }),
  createStep({
    title: 'Summary',
    description: 'Review and finalize',
  }),
];

export const INITIAL_STEPS_FOR_REPEAT = INITIAL_STEPS.map<Step>((step, i) =>
  createStep({
    ...step,
    status: i === 0 ? 'IN_PROGRESS' : 'COMPLETED',
  })
);
