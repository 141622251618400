import { type Components, type Theme } from '@mui/material';

export const MuiPaper: Components<Theme>['MuiPaper'] = {
  defaultProps: {
    variant: 'outlined',
  },
  styleOverrides: {
    root: {
      overflow: 'inherit',
    },
    outlined: ({ theme: { palette } }) => ({
      borderColor: palette.grey[300],
    }),
  },
};
