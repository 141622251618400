import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { isApiResponse, isDevelopment } from 'common/utils';
import { push } from 'redux-first-history';
import { AppRoutes } from 'router';
import type { RootState } from 'store';
import { addAppSuccessMessage } from 'store/features/alerts';
import { handleQueryError } from 'store/utils';

import { userApi } from './userApi';

export const userApiListener = createListenerMiddleware<RootState>();

userApiListener.startListening({
  matcher: isAnyOf(
    userApi.endpoints.getAccount.matchRejected,
    userApi.endpoints.updateAccount.matchRejected,
    userApi.endpoints.updateAccountAvatar.matchRejected,
    userApi.endpoints.deleteAccountAvatar.matchRejected,
    userApi.endpoints.updateProfile.matchRejected
  ),
  effect: ({ type, payload }, { dispatch }) => {
    const response = isApiResponse(payload) ? payload : undefined;
    handleQueryError(payload, dispatch, type, response?.data || 'Unable to save account information');
  },
});

userApiListener.startListening({
  matcher: userApi.endpoints.getAccount.matchFulfilled,
  effect: ({ payload: account }) => {
    if (account.id === null) {
      Sentry.setUser(null);
    } else {
      Sentry.setUser({
        id: `AID:${account.id?.toString()}`,
        ...(isDevelopment() ? { email: account.email } : {}),
      });
    }
  },
});

userApiListener.startListening({
  matcher: userApi.endpoints.updateProfile.matchFulfilled,
  effect: (_, { dispatch }) => {
    dispatch(userApi.util.invalidateTags(['ACCOUNT']));
    dispatch(addAppSuccessMessage('Your information has been saved'));

    dispatch(push(AppRoutes.Account));
  },
});
