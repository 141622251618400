import { Box, Stack, Typography, styled } from '@mui/material';

export const LegendRoot = styled(Stack)(({ theme: { spacing } }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: spacing(4),
}));

export const LegendColorSquare = styled(Box)(() => ({
  width: 16,
  height: 16,
  minWidth: 16,
}));

export const LegendItem = styled(Stack)(({ theme: { spacing } }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: spacing(1),
}));

export const LegendItemText = styled(Typography)(() => ({
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
}));

export const LegendList = styled(Stack)(({ theme: { spacing } }) => ({
  flexDirection: 'column',
  flexWrap: 'nowrap',
  gap: spacing(2),
}));
