import { createListenerMiddleware } from '@reduxjs/toolkit';
import { logger } from 'common/services';
import type { RootState } from 'store';

import { techCheckApi } from './techcheckApi';

export const techCheckApiLister = createListenerMiddleware<RootState>();

techCheckApiLister.startListening({
  matcher: techCheckApi.endpoints.postTechCheckResult.matchFulfilled,
  effect: ({ meta }) => {
    logger.info('[TechCheck] Result sent successfully', { data: meta.arg.originalArgs });
  },
});

techCheckApiLister.startListening({
  matcher: techCheckApi.endpoints.postTechCheckResult.matchRejected,
  effect: ({ error, meta }) => {
    logger.error('[TechCheck] Unable to send TechCheck result', { data: meta.arg.originalArgs, error });
  },
});
