import React, { FC } from 'react';

import { Box, Toolbar, useTheme } from '@mui/material';
import { useHover } from 'common/hooks';
import { motion } from 'framer-motion';
import { useAppDispatch, useAppSelector } from 'store';
import { appToggleDrawerCollapsed, selectIsDrawerCollapsed, selectIsDrawerDisplayed } from 'store/features/app';

import { ToggleDrawerButton } from '../ToggleDrawerButton';
import { COLLAPSED_DRAWER_WIDTH, DRAWER_WIDTH, DesktopDrawer } from './DesktopSidebar.styled';

type Props = {
  enableCollapse: boolean;
};

export const DesktopSidebar: FC<Props> = ({ enableCollapse, children }) => {
  const dispatch = useAppDispatch();
  const { zIndex } = useTheme();
  const displayDrawer = useAppSelector(selectIsDrawerDisplayed);
  const isDrawerCollapsed = useAppSelector(selectIsDrawerCollapsed);
  const { transitions } = useTheme();
  const [ref, isHovered] = useHover();
  const width = enableCollapse && isDrawerCollapsed ? COLLAPSED_DRAWER_WIDTH : DRAWER_WIDTH;

  const toggleDrawer = () => {
    dispatch(appToggleDrawerCollapsed());
  };

  return (
    <motion.div style={{ zIndex: zIndex.appBar - 1 }} initial={{ width }} animate={{ width }}>
      <DesktopDrawer
        open={displayDrawer}
        variant="persistent"
        transitionDuration={{
          appear: transitions.duration.complex,
          enter: transitions.duration.complex,
          exit: transitions.duration.complex,
        }}
      >
        <Toolbar />
        <Box position="relative" ref={ref}>
          {enableCollapse && isHovered && <ToggleDrawerButton isCollapsed={isDrawerCollapsed} onClick={toggleDrawer} />}
          {children}
        </Box>
      </DesktopDrawer>
    </motion.div>
  );
};
