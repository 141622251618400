import { styled } from '@mui/material';
import { omitForwardedProps } from 'common/utils';

type Props = {
  open: boolean;
};

export const MainContainer = styled('div', {
  name: 'MainContainer',
  shouldForwardProp: omitForwardedProps<Props>('open'),
})<Props>(({ theme: { transitions, breakpoints }, open }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  transition: transitions.create('margin', {
    easing: transitions.easing.sharp,
    duration: transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: transitions.create('margin', {
      easing: transitions.easing.easeOut,
      duration: transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
  [breakpoints.down('sm')]: {
    marginLeft: 0,
  },
}));
